@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Montserrat', sans-serif;
  background: var(--background-grey-100, #1a202c);
  padding-top: env(safe-area-inset-top, 0px);
  padding-left: env(safe-area-inset-left, 0px);
  padding-right: env(safe-arepadding-right, 0px);
  padding-left: env(safe-arepadding-left, 0px);
  color: var(--text-grey-900);
}

.main-font {
  font-family: 'Montserrat', sans-serif;
}

.ud-toast-container {
  padding-top: env(safe-area-inset-top, 0px);
}

.loader {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.popup-content {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
  background: var(--background-blank) !important;
}

.title {
  font-size: 24px;
}

.light {
  --usdoku-purple: #7f9cf5;
  /* Block numbers */
  --number-correct-color: #3d4960;
  --number-pending-color: #9fa5bc;
  --number-incorrect-color: #fd0000;
  --number-pencil-color: #92949b;

  /* Block backgrounds */
  --block-selected-color: #c5ddfe;
  --block-hover-color: #e1edfe;

  /* Block borders */
  --border-inner: #bfc4d3;
  --border-outer: #3d4a60;

  /* Fonts */
  --system-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  --game-font: Source Sans Pro, sans-serif;
  --number-selected-color: black;

  /* Game Control Buttons */
  --score-star-color: #fff6a3;
  --eraser-color: #ffe0b2;
  --pencil-fill-color: #ffe0b2;
  --pencil-active-color: #1ea7fd;
  --pencil-inactive-color: #f5f5f5;

  /* Settings */
  --toggle-green: #19ab27;
  --toggle-dark-green: #128d15;

  --text-grey-500: rgb(107 114 128);
  --text-grey-600: rgb(75 85 99);
  --text-grey-700: rgb(55 65 81);
  --text-grey-800: rgb(31 41 55);
  --text-grey-900: rgb(17 24 39);

  --text-purple-800: rgb(107 33 168);

  --dark-mode-toggle-label-text: rgb(107 114 128);

  /* Backgrounds */
  --background-blank: #ffffff;
  --background-grey-100: #f7fafc;
  --background-grey-200: rgb(229 231 235);
  --background-grey-300: rgb(209 213 219);
  --sudoku-grid-background: #ffffff;

  --input-mode-selector-background: var(--sudoku-grid-background);
}

.dark {
  --usdoku-purple: #7f9cf5;
  /* Block numbers */
  --number-correct-color: white;
  --number-pending-color: #9fa5bc;
  --number-incorrect-color: #fd0000;
  --number-pencil-color: #92949b;

  /* Block backgrounds */
  --block-selected-color: #424242;
  --block-hover-color: black;

  /* Block borders */
  --border-inner: rgb(51 65 85);
  --border-outer: rgb(107 114 128);

  /* Fonts */
  --system-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  --game-font: Source Sans Pro, sans-serif;
  --number-selected-color: white;

  /* Game Control Buttons */
  --score-star-color: #fff6a3;
  --eraser-color: #ffe0b2;
  --pencil-fill-color: #ffe0b2;
  --pencil-active-color: #1ea7fd;
  --pencil-inactive-color: #f5f5f5;

  /* Settings */
  --toggle-green: #19ab27;
  --toggle-dark-green: #128d15;

  --text-grey-500: rgb(107 114 128);
  --text-grey-600: rgb(156 163 175);
  --text-grey-700: rgb(209 213 219);
  --text-grey-800: rgb(229 231 235);
  --text-grey-900: rgb(243 244 246);

  --text-purple-800: rgb(233 213 255);
  --dark-mode-toggle-label-text: rgb(209 213 219);

  /* Backgrounds */
  --background-blank: #000000;
  --background-grey-100: rgb(51 65 85);
  --background-grey-200: rgb(30 41 59);
  --background-grey-300: rgb(15 23 42);
  --sudoku-grid-background: #1a202c;

  --input-mode-selector-background: rgb(15 23 42);
}

input[type='text'],
textarea {
  background: var(--background-blank);
}

/* React toggle */
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: var(--toggle-green);
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128d15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19ab27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
  -moz-box-shadow: 0px 0px 3px 2px #0099e0;
  box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
  -moz-box-shadow: 0px 0px 5px 5px #0099e0;
  box-shadow: 0px 0px 5px 5px #0099e0;
}
